import { Form } from '@pankod/refine-antd'

export function useWatch(name: string) {
  const formInstance = Form.useFormInstance()
  return Form.useWatch(name, formInstance)
}

export function useFormContext() {
  const formInstance = Form.useFormInstance()
  const { setFieldsValue } = formInstance
  return { useWatch: useWatch, setValues: setFieldsValue }
}
