import { DataProvider } from '@nartex/api-platform'
import { notificationProvider } from '@pankod/refine-antd'
import { Refine } from '@pankod/refine-core'
import routerProvider from '@pankod/refine-react-router-v6'
import { memo, Suspense, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { LoginPage } from 'src/UI/LoginPage'

import { antiCorruptionLayerProxy } from './adapters/antiCorruptionLayerProxy'
import type { IEnv } from './adapters/Env'
import { useEnv, EnvContext } from './adapters/Env'
import { HTTPClient, HttpClientContext } from './adapters/HTTPClient'
import { useI18nProvider } from './adapters/i18nProvider'
import { factoryAuthProvider } from './authProvider'
import { SetPassword } from './Pages/SetPassword'
import { resources } from './Resources'
import { Menu, Title, LoadingPage, Layout } from './UI'
import { Footer } from './UI/Footer'
import './UI/index.less'

type Props = {
  env: IEnv
}

// comment

export function App(props: Props) {
  const { env } = props

  return (
    <Suspense fallback={<LoadingPage />}>
      <EnvContext.Provider value={env}>
        <AppContent />
      </EnvContext.Provider>
    </Suspense>
  )
}

const reactQueryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: 10 * 1000, // 10 seconds
    },
  },
}

const customRouterProvider = {
  ...routerProvider,
  Link,
  routes: [
    {
      element: <SetPassword />,
      path: '/set_password',
    },
  ],
}

const AppContent = memo(function AppContent() {
  const env = useEnv()
  const i18nProvider = useI18nProvider()

  const httpClient = useMemo(
    () =>
      HTTPClient({
        getAccessToken() {
          return Promise.resolve('') // TODO : get the real user token
        },
        baseURL: env.API_URL,
      }),
    [env.API_URL],
  )

  const dataProvider = useMemo(
    () =>
      antiCorruptionLayerProxy(
        DataProvider({
          httpClient: httpClient,
          apiUrl: env.API_URL,
        }),
      ),
    [env.API_URL, httpClient],
  )

  const authProvider = useMemo(
    () => factoryAuthProvider({ apiUrl: env.API_URL }),
    [env.API_URL],
  )

  return (
    <HttpClientContext.Provider value={httpClient}>
      <Refine
        routerProvider={customRouterProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        reactQueryClientConfig={reactQueryClientConfig}
        resources={resources}
        Sider={Menu}
        Title={Title}
        Footer={Footer}
        Layout={Layout}
        authProvider={authProvider}
        LoginPage={LoginPage}
        notificationProvider={notificationProvider}
      />
    </HttpClientContext.Provider>
  )
})
