import type { Partner } from '@easyguide/types'
import { cleanHydraId } from '@nartex/api-platform'
import { Edit, Form, useForm } from '@pankod/refine-antd'
import { useParams } from 'react-router'
import CSVtoJSON from 'src/components/CsvImporter'

import { FormInputs } from './FormTemplate'

export function EditView() {
  const { formProps, saveButtonProps, queryResult, id } = useForm<Partner>({
    action: 'edit',
    redirect: 'list',
  })

  const params = useParams()
  function formatOrder(data: any) {
    const users = [] as any
    data.forEach((dt: any) => {
      users.push(Object.values(dt)[0])
    })
    return {
      partnerId: cleanHydraId(params.id!),
      orderIds: users,
    }
  }

  return (
    <Edit
      title={`Édition : ${editPartnerTitle(queryResult?.data?.data)}`}
      canDelete={false}
      saveButtonProps={saveButtonProps}
      recordItemId={id}
      headerButtons={(headerParams) => {
        const { defaultButtons } = headerParams
        return (
          <>
            <CSVtoJSON
              resource="orders/reassign"
              modalTitle="Réassigner des commandes"
              formatter={formatOrder}
            />
            {defaultButtons}
          </>
        )
      }}
    >
      <Form {...formProps} layout="vertical">
        <FormInputs edit />
      </Form>
    </Edit>
  )
}

function editPartnerTitle(partner: Partner | undefined) {
  return `${partner?.firstname} ${partner?.lastname}`
}
