import { Form, Select } from '@pankod/refine-antd'

import type { AutoCompleteString } from '..'

type Props<T> = {
  label: string
  name: AutoCompleteString<keyof T>
  required?: boolean
  options: any[]
  optionKey: string
  optionValue: string
  nullValue?: string
}
export function SelectInput<T>(props: Props<T>) {
  const {
    label,
    name,
    required = false,
    options,
    optionKey,
    optionValue,
    nullValue,
  } = props
  return (
    <Form.Item label={label} name={name as string} required={required}>
      <Select>
        {nullValue && <Select.Option value={null}>{nullValue}</Select.Option>}
        {options.map((option) => {
          return (
            <Select.Option value={option[optionValue]} key={option[optionKey]}>
              {option[optionKey]}
            </Select.Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}
