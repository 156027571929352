import xlsx from 'json-as-xlsx'

type CustomStat = {
  firstOpen: number
  callSupport: number
  stepValidated: number
  vimeo: number
  pdf: number
  precautionaryWarnings: number
  safetyRules: number
  info: number
}

export function exportCustomStats(stats: CustomStat) {
  const data = [
    {
      sheet: 'exportCustomStats',
      columns: [
        {
          label: 'firstOpen',
          value: function (stat: any) {
            return stat.firstOpen
          },
        },
        {
          label: 'callSupport',
          value: function (stat: any) {
            return stat.callSupport
          },
        },
        {
          label: 'watRdv',
          value: function (stat: any) {
            return stat.stepValidated
          },
        },
        {
          label: 'vimeo',
          value: function (stat: any) {
            return stat.vimeo
          },
        },
        {
          label: 'pdf',
          value: function (stat: any) {
            return stat.pdf
          },
        },
        {
          label: 'precautionaryWarnings',
          value: function (stat: any) {
            return stat.precautionaryWarnings
          },
        },
        {
          label: 'safetyRules',
          value: function (stat: any) {
            return stat.safetyRules
          },
        },
        {
          label: 'info',
          value: function (stat: any) {
            return stat.info
          },
        },
      ],
      content: [stats],
    },
  ]
  const settings = {
    fileName: `CustomStats_${new Date().toLocaleDateString('Fr')}`, // Name of the spreadsheet
    extraLength: 2, // A bigger number means that columns will be wider
    writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
  }
  xlsx(data, settings)
}
