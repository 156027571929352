import { ResourcePathEnum } from '@easyguide/types'

import { CreateView } from './Create'
import { EditView } from './Edit'

import { ListView } from './List'

export const partners = {
  name: ResourcePathEnum.partners,
  create: CreateView,
  list: ListView,
  edit: EditView,
}
