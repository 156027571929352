import { Button, Result, Space } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { useNavigate } from 'react-router'

type FallbackProps = {
  error: Error
  resetErrorBoundary: (...args: Array<unknown>) => void
}

export function ErrorPage(props: FallbackProps) {
  const { error } = props
  const navigate = useNavigate()
  const translate = useTranslate()

  return (
    <Result
      status="500"
      title={translate('pages.error.title')}
      subTitle={translate(error.message)}
      extra={
        <Space>
          <Button onClick={() => navigate(-1)}>
            {translate('pages.error.backHome')}
          </Button>
          <Button type="primary" onClick={() => window.location.reload()}>
            {translate('buttons.refresh')}
          </Button>
        </Space>
      }
    />
  )
}
