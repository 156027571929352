import env from '@beam-australia/react-env'
import { createContext, useContext } from 'react'

export type IEnv = ReturnType<typeof Env>

export function Env() {
  function resolve(
    name: string,
    getDefaultValue: () => string = () => isMissing(name),
  ): string {
    return env(name) ?? getDefaultValue()
  }

  const APP_ENV = validateAppEnv(resolve('APP_ENV', () => 'production'))

  return {
    APP_ENV: APP_ENV,
    IS_DEV: APP_ENV === 'development',
    BASE_URL: formatUrl(resolve('BASE_URL')),
    API_URL: formatUrl(resolve('API_URL')),
    SENTRY_DSN: resolve('SENTRY_DSN'),
    'X-CLIENT-SECRET': resolve('X_CLIENT_SECRET'),
  } as const
}

export const EnvContext = createContext<IEnv | undefined>(undefined)

export function useEnv(): IEnv {
  const resolved = useContext(EnvContext)

  if (!resolved) {
    throw new Error('Please provide an env object')
  }

  return resolved
}

function isMissing(name: string): never {
  throw new Error(`Missing env ${name}`)
}

function formatUrl(url: string) {
  return url.replace(/(\/)+$/, '') // remove trailing slash
}

function validateAppEnv(appEnv: string) {
  const validValues = ['development', 'production', 'integ']
  if (!validValues.includes(appEnv)) {
    throw new Error(
      `Invalid value for APP_ENV: ${JSON.stringify(
        appEnv,
      )}. Valid values are: ${validValues.join(', ')}`,
    )
  }

  return appEnv as 'development' | 'production' | 'integ'
}
