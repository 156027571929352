import { Logo } from '@easyguide/design'
import { AntdLayout, Card, Form, Input, Button } from '@pankod/refine-antd'
import { useNotification, useTranslate } from '@pankod/refine-core'
import '../UI/LoginPage.less'
import jwt_decode from 'jwt-decode'
import { useMutation } from 'react-query'
import { useEnv } from 'src/adapters/Env'
import { useHttpClient } from 'src/adapters/HTTPClient'

export type ILoginForm = {
  password: string
  password2: string
}

type JwtUrl = {
  iss: string
  exp: number
  id: string
  roles: string[]
}

type setPasswordForm = {
  password: string
}

export function SetPassword() {
  const [form] = Form.useForm()
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('jwt')
  const { id } = jwt_decode<JwtUrl>(token ?? '')
  const { open } = useNotification()
  const translate = useTranslate()
  const env = useEnv()
  const httpClient = useHttpClient()
  const { mutate, isLoading } = useMutation(
    async (value: setPasswordForm) => {
      const { password } = value
      try {
        await httpClient.put(
          `/password/${id}`,
          { password },
          { headers: { 'X-CLIENT-SECRET': `${env['X-CLIENT-SECRET']}` } },
        )
      } catch (error: any) {
        open?.({
          type: 'error',
          message: translate('notifications.error', { statusCode: error.code }),
        })
        throw error
      }
    },

    {
      onSuccess() {
        open?.({
          type: 'success',
          message: translate('notifications.createPassword'),
        })
        form.resetFields()
      },
    },
  )

  return (
    <AntdLayout className="login-page">
      <Card
        className="container"
        cover={<Logo />}
        headStyle={{ borderBottom: 0 }}
        style={{ padding: '20px' }}
      >
        <Form layout="vertical" form={form} onFinish={mutate}>
          <Form.Item label={translate('password.initialize')}>
            <span className="ant-form-text">{translate('password.rules')}</span>
          </Form.Item>
          <Form.Item
            name="password"
            label="Mot de passe"
            rules={[{ required: true }]}
            style={{ marginBottom: '24px' }}
          >
            <Input.Password autoComplete="off" size="large" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirmer"
            rules={[
              { required: true },
              (formInstance) => {
                const { getFieldValue } = formInstance
                return {
                  validator(_, value) {
                    if (value === getFieldValue('password')) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error(translate('password.verify')),
                    )
                  },
                }
              },
            ]}
            style={{ marginBottom: '24px' }}
          >
            <Input.Password autoComplete="off" size="large" />
          </Form.Item>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            block
            loading={isLoading}
          >
            Envoyer
          </Button>
        </Form>
      </Card>
    </AntdLayout>
  )
}
