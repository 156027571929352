import spec from '@easyguide/types/api/spec.json'

import type { TCountryCode } from 'countries-list'

import packageJson from '../package.json'

type Country = {
  key: TCountryCode
  label: string
}

export const config = {
  APP_VERSION: packageJson.version,
  APP_NAME: packageJson.name,
  APP_DISPLAY_NAME: packageJson.displayName,
  APP_RELEASE: `${packageJson.name}@${packageJson.version}` as const,
  API_VERSION: spec.info.version,
  features: {} as const,
} as const

export const availableCountries: Country[] = [{ key: 'FR', label: 'France' }]
