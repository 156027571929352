import { SelectInput } from './components/SelectInput'
import { TextInput } from './components/TextInput'

export const makeInputs = once(function makeInputs() {
  return {
    Text: TextInput,
    Select: SelectInput,
  }
})

export type AutoCompleteString<T> = T | (string & {})

// Small perf optimisation, each component will be defined once, at the whole app level (as expected if we did not use a factory function)
function once<T extends () => any>(func: T): T {
  let didRun = false
  let result: ReturnType<T>
  return function () {
    if (!didRun) {
      result = func()
      didRun = true
    }
    return result
  } as any
}
