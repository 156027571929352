import type { UserRead } from '@easyguide/types'
import { ResourcePathEnum } from '@easyguide/types'
import { List, Table, useTable, Space } from '@pankod/refine-antd'
import { EditButton } from 'src/components/ResourceButtons'
import { StyledRole } from 'src/components/StyledRole'

export function ListView() {
  const { tableProps } = useTable<UserRead>({
    resource: ResourcePathEnum.users,
    initialCurrent: 1,
    initialPageSize: 10,
  })
  return (
    <List title="Utilisateurs">
      <Table<UserRead> {...tableProps} rowKey={(user) => user.id}>
        <Table.Column
          dataIndex="firstname"
          title="Nom"
          sorter={{
            compare: function (a: UserRead, b: UserRead) {
              return a.firstname.localeCompare(b.firstname)
            },
          }}
        />
        <Table.Column
          dataIndex="lastname"
          title="Prénom"
          sorter={{
            compare: function (a: UserRead, b: UserRead) {
              return a.lastname.localeCompare(b.lastname)
            },
          }}
        />
        <Table.Column dataIndex={'email'} title={'Email'} />
        <Table.Column<UserRead>
          dataIndex={'roles'}
          title={'Rôle'}
          render={(roles: UserRead['roles']) => {
            return (
              <div style={{ display: 'flex' }}>
                {roles
                  .filter((role) => role !== 'ROLE_USER')
                  .map((role: string, index) => (
                    <StyledRole role={role} key={index} />
                  ))}
              </div>
            )
          }}
        />
        <Table.Column<UserRead>
          dataIndex="actions"
          render={(_, record) => {
            console.log(record)
            const id = record.id.replaceAll('/api/users/', '')
            return (
              <Space style={{ justifyContent: 'flex-end', width: '100%' }}>
                <EditButton recordItemId={id} />
              </Space>
            )
          }}
        />
      </Table>
    </List>
  )
}
