import type { Partner } from '@easyguide/types/api'
import { cleanHydraId } from '@nartex/api-platform'
import {
  AutoComplete,
  Create,
  Form,
  Space,
  Typography,
  useForm,
} from '@pankod/refine-antd'
import { useList } from '@pankod/refine-core'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { CsvPicker } from 'src/components/CsvPicker'

export function ListView() {
  const { data } = useList<Partner>({ resource: 'partners' })
  const [options, setOptions] = useState<{ value: string; label: string }[]>([])

  const { formProps, saveButtonProps, form } = useForm({
    resource: 'orders/reassign',
    action: 'edit',
    redirect: false,
    onMutationSuccess() {
      navigate('/partners')
    },
  })

  const { setFieldsValue } = form

  const partnerOptions = useMemo(() => {
    if (!data) return
    const filteredOptions: { value: string; label: string }[] = data.data.map(
      (partner) => {
        return {
          label: `${partner.firstname} ${partner.lastname} - ${partner.code}`,
          value: cleanHydraId(partner.id)!,
        }
      },
    )
    return filteredOptions
  }, [data])

  useEffect(() => {
    if (!partnerOptions) return
    setOptions(partnerOptions)
  }, [partnerOptions])

  const navigate = useNavigate()

  function formatOrder(csvDatas: any) {
    const users = [] as any
    csvDatas.forEach((csvData: any) => {
      users.push(Object.values(csvData)[0])
    })
    return users
  }

  function handleSearch(searchValue: string) {
    let res: { value: string; label: string }[] = []
    if (!searchValue) {
      res = partnerOptions!
    } else {
      partnerOptions!.forEach((option) => {
        if (
          option.label.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1
        ) {
          res.push(option)
        }
      })
    }
    setOptions(res)
  }

  return (
    <Create title={'Réssigner des commandes'} saveButtonProps={saveButtonProps}>
      <Space direction="vertical" style={{ gap: 32 }}>
        <Typography.Title level={5}>
          Choisissez un partenaire dans la liste et un fichier (csv / xlsx)
          contenant une unique colonne référençant les commandes concernées
        </Typography.Title>
        <Form {...formProps}>
          <Form.Item label={'Partenaire'} name={'partnerId'} required>
            <AutoComplete
              options={options}
              style={{ width: '400px' }}
              onSearch={handleSearch}
            ></AutoComplete>
          </Form.Item>
          <CsvPicker setValue={setFieldsValue} formatter={formatOrder} />
        </Form>
      </Space>
    </Create>
  )
}
