import { UploadOutlined } from '@ant-design/icons'
import type { UploadProps, RcFile } from '@pankod/refine-antd'
import {
  Form,
  Upload,
  Button,
  Space,
  Typography,
  Input,
} from '@pankod/refine-antd'
import { getEmojiFlag } from 'countries-list'
import { useState } from 'react'
import { makeInputs } from 'src/ui-kit'
import { v4 } from 'uuid'

import { availableCountries } from '../../config'
import { useFormContext } from '../../libs/useWatch'

const FormInput = makeInputs()

type FormContentProps = {
  type: 'pdf' | 'vimeo'

  edit?: boolean
}

export function FormContent(props: FormContentProps) {
  const { type, edit = false } = props
  if (type === 'pdf') {
    return <PdfFormContent edit={edit} />
  } else {
    return <VimeoFormContent />
  }
}

const Options = availableCountries.map((country) => {
  return {
    key: country.key,
    label: getEmojiFlag(country.key) + ' ' + country.label,
  }
})

function PdfFormContent(props: { edit?: boolean }) {
  const { edit = false } = props
  const { useWatch } = useFormContext()
  const fileName = useWatch('fileName')

  const actualValue = edit ? fileName : undefined

  return (
    <>
      <Form.Item label={''} name={'fileName'} hidden>
        <Input type={'text'} />
      </Form.Item>
      <UploadData actualValue={actualValue} />
      <FormInput.Text label="Titre du document" name="displayName" required />
      <FormInput.Select
        label="Langue"
        name="languages"
        options={Options}
        optionKey="label"
        optionValue="key"
        nullValue="Multilingue"
      />
    </>
  )
}

function VimeoFormContent() {
  return (
    <>
      <FormInput.Text
        label="Lien Viméo"
        name="url"
        required
        rules={[
          {
            pattern: /^https:\/\/vimeo\.com\/\d+$/,
            message: 'Format accepté: https://vimeo.com/12345678',
          },
        ]}
      />
      <FormInput.Text label="Titre de la vidéo" name="displayName" required />
      <FormInput.Select
        label="Langue"
        name="languages"
        options={Options}
        optionKey="label"
        optionValue="key"
        nullValue="Multilingue"
      />
    </>
  )
}

function UploadData(props: { actualValue?: string }) {
  const { actualValue } = props
  const { setValues } = useFormContext()
  const [fileList, setFileList] = useState<RcFile[]>([])

  function prepareFile(file: RcFile) {
    setFileList([file])
    if (file) {
      setValues({ file: file })
    }
  }

  const importProps: UploadProps = {
    onRemove() {
      setFileList([])
    },
    beforeUpload(file) {
      prepareFile(file)
      return false
    },
    itemRender(originNode) {
      return originNode
    },
    fileList: [],
  }

  const displayName = fileList[0]?.name ?? actualValue ?? undefined

  return (
    <>
      <Form.Item label={'Fichier PDF'} name={'file'} required>
        <Upload {...importProps}>
          <Space size={40}>
            {displayName && (
              <Typography.Text strong>{displayName}</Typography.Text>
            )}
            <Button icon={<UploadOutlined />}>Choisir un fichier</Button>
            <Typography.Text>Max -- Mo / fichier</Typography.Text>
          </Space>
        </Upload>
      </Form.Item>
    </>
  )
}

type DocCreate = {
  newId: string
  displayName: string
  fileName: string
  size: number
  mimeType: string
  extension: string
  languages: string
  file: File
}

export function initDocument(
  initialFile: File,
  displayName: string,
  languages: string,
): DocCreate {
  const id = v4()
  const mimeType = initialFile.type
  const extension =
    // @ts-ignore
    mime.getExtension(mimeType) ?? initialFile.name.split('.').at(-1)

  return {
    newId: id,
    displayName: displayName,
    fileName: `${id}.${extension}`,
    size: initialFile.size,
    mimeType,
    extension: extension ?? '',
    languages,
    file: new File([initialFile], `${id}.${extension}`, {
      type: mimeType ?? undefined,
    }),
  }
}
