import { Skeleton, AntdLayout } from '@pankod/refine-antd'
import type { LayoutProps } from '@pankod/refine-core'
import type { PropsWithChildren } from 'react'
import { Suspense } from 'react'
import { ErrorBoundary as _ErrorBoundary } from 'react-error-boundary'

import { ErrorPage } from './ErrorPage'

const ErrorBoundary = _ErrorBoundary as any

export function Layout(props: PropsWithChildren<LayoutProps>) {
  const { children, Sider, Footer, Header, OffLayoutArea } = props
  return (
    <AntdLayout style={{ height: '100vh', flexDirection: 'row' }}>
      {Sider && <Sider />}
      <AntdLayout>
        {Header && <Header />}
        <AntdLayout.Content style={{ overflowY: 'scroll' }}>
          <div style={{ padding: 24, minHeight: 360 }}>
            <Suspense fallback={<Skeleton />}>
              <ErrorBoundary FallbackComponent={ErrorPage}>
                {children}
              </ErrorBoundary>
            </Suspense>
          </div>
        </AntdLayout.Content>
        {Footer && <Footer />}
      </AntdLayout>
      {OffLayoutArea && <OffLayoutArea />}
    </AntdLayout>
  )
}
