/*
 * Beware, this code is AUTO-GENERATED
 * Any manual changes on this file would be eaten by the VØ|D
 */

type Integer = number

type Year = Integer
type Month = Integer
type Day = Integer
type Hour = Integer
type Minute = Integer
type Second = Integer
type HoursOffset = Integer
type SecondsOffset = Integer
export type DateTimeString =
  `${Year}-${Month}-${Day}T${Hour}:${Minute}:${Second}+${HoursOffset}:${SecondsOffset}`

export type IriReference = `/api/${ResourcePathEnum}/${string}`

export enum ResourceTypeEnum {
  'DocumentRead' = 'DocumentRead',
  'Order' = 'Order',
  'Partner' = 'Partner',
  'Statistic' = 'Statistic',
  'UserRead' = 'UserRead',
}
export enum ResourcePathEnum {
  'documents' = 'documents',
  'orders' = 'orders',
  'partners' = 'partners',
  'statistics' = 'statistics',
  'users' = 'users',
  'login' = 'login',
}

export type DocumentRead = {
  readonly '@id': IriReference
  readonly '@type': ResourceTypeEnum.DocumentRead

  readonly id: IriReference
  displayName: string
  fileName: string
  /** CodegenError: Cannot generate property type : {"type":["integer","null"]} */
  size: any
  mimeType: string
  extension: string
  languages: string[]
  url: string
  /** CodegenError: Cannot generate property type : {"type":["string","null"]} */
  deviceFileName: any
  createdAt: DateTimeString // date-time
  updatedAt: DateTimeString // date-time
}

export type Order = {
  readonly '@id': IriReference
  readonly '@type': ResourceTypeEnum.Order

  readonly id: IriReference
  orderCode: string
  partner: IriReference // iri-reference
  loginPartnerCode: string
  loginInstanceCode: string
  realInstanceCode: string
  pdfName: string
  createdAt: DateTimeString // date-time
  updatedAt: DateTimeString // date-time
  /** CodegenError: Cannot generate property type : {"type":["string","null"],"format":"date-time"} */
  deletedAt: any // date-time
  readonly deleted: boolean
}

export type Partner = {
  readonly '@id': IriReference
  readonly '@type': ResourceTypeEnum.Partner

  readonly id: IriReference
  /** CodegenError: Cannot generate property type : {"type":["string","null"]} */
  society: any
  firstname: string
  lastname: string
  phoneNumber: string
  email: string
  country: string
  code: string
  orders: IriReference[]
  createdAt: DateTimeString // date-time
  updatedAt: DateTimeString // date-time
  /** CodegenError: Cannot generate property type : {"type":["string","null"],"format":"date-time"} */
  deletedAt: any // date-time
  /** CodegenError: Cannot generate property type : {"type":["string","null"]} */
  newId: any
  readonly deleted: boolean
}

export type Statistic = {
  readonly '@id': IriReference
  readonly '@type': ResourceTypeEnum.Statistic

  readonly id: IriReference
  recordedAt: DateTimeString // date-time
  eventKey: StatisticEventKeyEnum
  deviceId: string
  /** CodegenError: Cannot generate property type : {"type":["integer","null"]} */
  stepIndex: any
  /** CodegenError: Cannot generate property type : {"type":["string","null"]} */
  stepId: any
  /** CodegenError: Cannot generate property type : {"type":["string","null"]} */
  stepName: any
  /** CodegenError: Cannot generate property type : {"type":["string","null"]} */
  userId: any
  /** CodegenError: Cannot generate property type : {"type":["string","null"]} */
  customerNumber: any
  /** CodegenError: Cannot generate property type : {"type":["integer","null"]} */
  taskIndex: any
  /** CodegenError: Cannot generate property type : {"type":["string","null"]} */
  taskId: any
  /** CodegenError: Cannot generate property type : {"type":["string","null"]} */
  taskName: any
  newState: StatisticNewStateEnum
  /** CodegenError: Cannot generate property type : {"type":["string","null"]} */
  itemId: any
  /** CodegenError: Cannot generate property type : {"type":["string","null"]} */
  itemName: any
  /** CodegenError: Cannot generate property type : {"type":["string","null"]} */
  itemType: any
  /** CodegenError: Cannot generate property type : {"type":["string","null"]} */
  screenClass: any
  /** CodegenError: Cannot generate property type : {"type":["string","null"]} */
  screenName: any
  /** CodegenError: Cannot generate property type : {"type":["string","null"]} */
  partnerCode: any
  createdAt: DateTimeString // date-time
  updatedAt: DateTimeString // date-time
  /** CodegenError: Cannot generate property type : {"type":["string","null"],"format":"date-time"} */
  deletedAt: any // date-time
  /** CodegenError: Cannot generate property type : {"type":["string","null"]} */
  newId: any
  readonly deleted: boolean
}

export enum StatisticEventKeyEnum {
  'firstOpen' = 'firstOpen',
  'stepValidated' = 'stepValidated',
  'stepTaskEdited' = 'stepTaskEdited',
  'callSupport' = 'callSupport',
  'viewItem' = 'viewItem',
  'viewScreen' = 'viewScreen',
}

export enum StatisticNewStateEnum {
  'todo' = 'todo',
  'done' = 'done',
  'notApplicable' = 'notApplicable',
}

export type UserRead = {
  readonly '@id': IriReference
  readonly '@type': ResourceTypeEnum.UserRead
  readonly id: IriReference
  email: string
  firstname: string
  lastname: string
  roles: string[]
  readonly isAdmin: boolean
  readonly isCommercial: boolean
  poolRef: string
  poolName: string
}

export type UserWrite = {
  readonly id: string
  email: string
  firstname: string
  lastname: string
  /** CodegenError: Cannot generate property type : {"type":["string","null"]} */
  password: any
  roles: string[]
  poolRef: string
  poolName: string
}
