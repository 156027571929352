import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

import 'dayjs/locale/fr'

i18n
  .use(Backend)
  .use(detector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['fr'],
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json', // locale files path
    },
    defaultNS: 'common',
    fallbackLng: ['fr'],
  })

export default i18n
