import { colors } from '@easyguide/design'
import type { Statistic } from '@easyguide/types/api'
import { ResourcePathEnum } from '@easyguide/types/api'
import { Table, Statistic as Stat, useTable } from '@pankod/refine-antd'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { eventKeys } from 'src/data/eventKeys'
import '../index.less'

type CalculatedStatsProps = {
  value: number | undefined
  title: string
}

export function CalculatedStats(props: CalculatedStatsProps) {
  const { value, title } = props
  return (
    <Stat
      title={title}
      value={value}
      valueStyle={{ color: colors.ambient }}
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    />
  )
}

type TableStatProps = {
  dates: { start: Date; end: Date }
  filter?: { event?: string; partner?: string }
}
export function TableStat(props: TableStatProps) {
  const { filter, dates } = props
  const { tableProps, setFilters } = useTable<Statistic>({
    resource: ResourcePathEnum.statistics,
    initialCurrent: 1,
    initialPageSize: 10,
    initialFilter: [
      {
        field: 'eventKey',
        operator: 'eq',
        value: filter?.event,
      },
      {
        field: 'recordedAt',
        operator: 'lte',
        value: new Date(dayjs(dates.end).endOf('day').format()),
      },
      {
        field: 'recordedAt',
        operator: 'gte',
        value: new Date(dayjs(dates.start).startOf('day').format()),
      },
    ],
    permanentSorter: [
      {
        field: 'recordedAt',
        order: 'desc',
      },
    ],
  })

  useEffect(() => {
    setFilters([
      {
        field: 'eventKey',
        operator: 'eq',
        value: filter?.event,
      },
      {
        field: 'recordedAt',
        operator: 'lte',
        value: new Date(dayjs(dates.end).endOf('day').format()),
      },
      {
        field: 'recordedAt',
        operator: 'gte',
        value: new Date(dayjs(dates.start).startOf('day').format()),
      },
      { field: 'partnerCode', operator: 'contains', value: filter?.partner },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates, filter])

  const event = Object.entries(eventKeys).filter((key) => {
    return key[0] === filter?.event
  })

  return (
    <Table<Statistic> {...tableProps} rowKey={(statistic) => statistic.id}>
      {event[0][1].map((field, index) => {
        if (field === 'recordedAt') {
          return (
            <Table.Column<Statistic>
              key={index}
              dataIndex={field}
              title={field}
              render={(date: string) =>
                `${new Date(date).toLocaleDateString('Fr')}: ${new Date(
                  date,
                ).toLocaleTimeString('Fr')}`
              }
            />
          )
        }
        return (
          <Table.Column<Statistic>
            dataIndex={field}
            title={field}
            key={index}
          />
        )
      })}
    </Table>
  )
}

export function filterEvent(
  data: Statistic[] | readonly Statistic[],
  eventKey: string,
  screenKey?: string | undefined,
  screen?: string | number,
) {
  return data.filter((event) => {
    if (screenKey === 'screenClass') {
      return event.eventKey === eventKey && event[screenKey] === screen
    }
    return null
  }).length
}
