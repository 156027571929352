import type { DocumentRead } from '@easyguide/types'
import { Edit, Form, useForm } from '@pankod/refine-antd'
import { useInvalidate, useNavigation } from '@pankod/refine-core'
import { useNavigate } from 'react-router'

import { FormContent } from './FormContent'

type EditViewProps = {
  type: 'pdf' | 'vimeo'
}
export function EditView(props: EditViewProps) {
  const { type } = props
  const resourceTo = type === 'pdf' ? 'pdfDocuments' : 'vimeoDocuments'
  const { list } = useNavigation()
  const navigate = useNavigate()
  const invalidate = useInvalidate()
  const { formProps, saveButtonProps, queryResult, id } = useForm<DocumentRead>(
    {
      resource: `documents/${type}`,
      action: 'edit',
      redirect: 'list',
      onMutationSuccess() {
        invalidate({
          resource: 'documents',
          invalidates: ['list'],
        })
        list(resourceTo, 'replace')
      },
    },
  )

  function redirectList() {
    invalidate({
      resource: 'documents',
      invalidates: ['list'],
    })
    navigate(`/documents/${type}`)
  }

  const displayName = queryResult?.data?.data?.displayName

  const title = `Edition: ${displayName ?? ''}`

  return (
    <Edit
      title={title}
      canDelete={true}
      saveButtonProps={saveButtonProps}
      recordItemId={id}
      deleteButtonProps={{
        onSuccess: redirectList,
      }}
    >
      <Form {...formProps} layout="vertical">
        <FormContent type={type} edit />
      </Form>
    </Edit>
  )
}
