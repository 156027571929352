export const borderWidths = {
  "hairline": "1px"
} as const

export const colors = {
  "onError": "rgba(255, 255, 255, 1)",
  "onAccent": "rgba(255, 255, 255, 1)",
  "onAmbient": "rgba(255, 255, 255, 1)",
  "onSurface": "rgba(36, 35, 35, 1)",
  "onBackground": "rgba(36, 35, 35, 1)",
  "background": "rgba(250, 250, 250, 1)",
  "surfaceVariant": "rgba(255, 255, 255, 0.5)",
  "surface": "rgba(255, 255, 255, 1)",
  "gray": "rgba(36, 35, 35, 0.6)",
  "grayUltraLight": "rgba(36, 35, 35, 0.05)",
  "grayLight": "rgba(36, 35, 35, 0.25)",
  "success": "rgba(79, 139, 60, 1)",
  "ambient": "rgba(86, 178, 233, 1)",
  "successVariant": "rgba(221, 238, 216, 1)",
  "ambientVariant": "rgba(0, 128, 200, 1)",
  "accentLight": "rgba(250, 235, 205, 1)",
  "accent": "rgba(239, 182, 62, 1)",
  "accentVariant": "rgba(229, 113, 44, 1)",
  "error": "rgba(234, 51, 35, 1)"
} as const

export const fontFamilies = {
  "buttonSmall": "Ropa Sans",
  "buttonLink": "Open Sans",
  "buttonMedium": "Ropa Sans",
  "bodyXsBold": "Open Sans",
  "bodyXs": "Open Sans",
  "bodySBold": "Open Sans",
  "bodyS": "Open Sans",
  "bodyMBold": "Open Sans",
  "bodyM": "Open Sans",
  "headingXsStroke": "Open Sans",
  "headingXs": "Open Sans",
  "headingS": "Ropa Sans",
  "headingM": "Ropa Sans",
  "headingL": "Ropa Sans"
} as const

export const fontSizes = {
  "buttonSmall": "0.75rem",
  "buttonLink": "0.75rem",
  "buttonMedium": "1rem",
  "bodyXsBold": "0.625rem",
  "bodyXs": "0.625rem",
  "bodySBold": "0.875rem",
  "bodyS": "0.875rem",
  "bodyMBold": "1rem",
  "bodyM": "1rem",
  "headingXsStroke": "0.9375rem",
  "headingXs": "0.9375rem",
  "headingS": "1.75rem",
  "headingM": "2rem",
  "headingL": "2.75rem"
} as const

export const fontWeights = {
  "buttonSmall": 400,
  "buttonLink": 400,
  "buttonMedium": 400,
  "bodyXsBold": 700,
  "bodyXs": 400,
  "bodySBold": 700,
  "bodyS": 400,
  "bodyMBold": 700,
  "bodyM": 400,
  "headingXsStroke": 300,
  "headingXs": 300,
  "headingS": 400,
  "headingM": 400,
  "headingL": 400
} as const

export const letterSpacings = {
  "buttonSmall": "0em",
  "buttonLink": "0em",
  "buttonMedium": "0em",
  "bodyXsBold": "0em",
  "bodyXs": "0em",
  "bodySBold": "0em",
  "bodyS": "0em",
  "bodyMBold": "0em",
  "bodyM": "0em",
  "headingXsStroke": "0em",
  "headingXs": "0em",
  "headingS": "0.01em",
  "headingM": "0.01em",
  "headingL": "0.01em"
} as const

export const lineHeights = {
  "buttonSmall": "1.00",
  "buttonLink": "1.33",
  "buttonMedium": "1.00",
  "bodyXsBold": "1.60",
  "bodyXs": "1.60",
  "bodySBold": "1.14",
  "bodyS": "1.14",
  "bodyMBold": "1.50",
  "bodyM": "1.50",
  "headingXsStroke": "1.07",
  "headingXs": "1.07",
  "headingS": "1.14",
  "headingM": "1.25",
  "headingL": "1.09"
} as const

export const radii = {
  "rounded": "16px",
  "hard": "0px"
} as const

export const shadows = {
  "elevation1": "0px 0px 32px rgba(164, 154, 134, 0.2)"
} as const

export const spacing = {
  "huge": "6rem",
  "large": "4rem",
  "big": "3rem",
  "medium": "2rem",
  "small": "1rem",
  "tiny": "0.5rem",
  "micro": "0.25rem"
} as const