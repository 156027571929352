import { Form, Input } from '@pankod/refine-antd'

import type { AutoCompleteString } from '..'

type Props<T> = {
  label: string
  name: AutoCompleteString<keyof T>
  required?: boolean
  type?: string
  readOnly?: boolean
  rules?: any
}

export function TextInput<T>(props: Props<T>) {
  const {
    label,
    name,
    required = false,
    type = 'text',
    readOnly = false,
    rules,
  } = props

  if (type === 'email') {
    return (
      <Form.Item
        label={label}
        name={name as string}
        rules={[
          {
            type: 'email',
            message: 'email invalide',
          },
        ]}
        required={required}
      >
        <Input type={type} disabled={readOnly} />
      </Form.Item>
    )
  }
  return (
    <Form.Item
      label={label}
      name={name as string}
      required={required}
      rules={rules}
    >
      <Input type={type} disabled={readOnly} />
    </Form.Item>
  )
}
