import { makeInputs } from 'src/ui-kit'

const Input = makeInputs()

type FormInputProps = {
  edit?: boolean
}

export function FormInputs(props: FormInputProps) {
  const { edit = false } = props
  return (
    <>
      <Input.Text label="Nom" name="firstname" required />
      <Input.Text label="Prénom" name="lastname" required />
      <Input.Text label="Société" name="society" />
      <Input.Text label="Téléphone" name="phoneNumber" required />
      <Input.Text type="email" label="Email" name="email" required />
      <Input.Text label="Pays" name="country" required />
      <Input.Text
        label="Code partenaire"
        name="code"
        required
        readOnly={edit}
      />
    </>
  )
}
