import type { DocumentRead } from '@easyguide/types'
import { useForm, Form, Create } from '@pankod/refine-antd'

import { useInvalidate, useNavigation } from '@pankod/refine-core'

import { FormContent } from './FormContent'

type CreateViewProps = {
  type: 'pdf' | 'vimeo'
}
export function CreateView(props: CreateViewProps) {
  const { type } = props
  const invalidate = useInvalidate()
  const resourceTo = type === 'pdf' ? 'pdfDocuments' : 'vimeoDocuments'

  const { list } = useNavigation()
  const { formProps, saveButtonProps } = useForm<DocumentRead>({
    onMutationSuccess() {
      invalidate({
        resource: 'documents',
        invalidates: ['list'],
      })
      list(resourceTo, 'replace')
    },
  })

  const title = type === 'pdf' ? "Ajout d'un document PDF'" : "Ajout d'un vidéo"

  const defaultLang = type === 'pdf' ? 'FR' : null

  return (
    <Create saveButtonProps={saveButtonProps} title={title}>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{ languages: defaultLang }}
      >
        <FormContent type={type} />
      </Form>
    </Create>
  )
}
