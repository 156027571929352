import type { AuthProvider } from '@pankod/refine-core'
import axios from 'axios'

type LoginProps = {
  username: string
  password: string
  remember: boolean
}

type AuthProviderConfig = {
  apiUrl: string
}

export function factoryAuthProvider(config: AuthProviderConfig) {
  const { apiUrl } = config

  const authProvider: AuthProvider = {
    async login(props: LoginProps) {
      const test = await tryConnect(props)
      if (test?.status === 200 && !test?.data.error) {
        localStorage.setItem('jwt', test?.data.apiToken)
        return
      }
      // eslint-disable-next-line no-throw-literal
      throw {
        name: 'Connexion impossible',
        message: 'Mauvais utilisateur/ mot de passe',
      }
    },
    async logout() {
      localStorage.removeItem('jwt')
    },
    checkError: async function (error: any) {
      if (error.response.status === 401 || error.response.status === 403) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('/')
      }
    },
    async checkAuth() {
      if (localStorage.getItem('jwt')) {
        return
      }
      throw Error()
    },
    async getPermissions() {
      const jwt = localStorage.getItem('jwt')

      if (jwt) {
        const parsedUser = JSON.parse(jwt)
        return Promise.resolve(parsedUser.roles)
      }
      throw Error()
    },
  }
  async function tryConnect(props: LoginProps) {
    const { username, password } = props
    try {
      const res = await axios.post(`${apiUrl}/login`, {
        login: username,
        pwd: password,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }
  return authProvider
}
