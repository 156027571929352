import { Typography } from '@pankod/refine-antd'
import { config } from 'src/config'

import './Footer.less'

export function Footer() {
  return (
    <div className="NxFooter">
      <Typography.Text style={{ fontSize: 12, margin: 4 }}>
        {config.APP_DISPLAY_NAME} (v{config.APP_VERSION}) — API (v
        {config.API_VERSION})
      </Typography.Text>
    </div>
  )
}
