import type { UserWrite } from '@easyguide/types/api'
import type { BaseRecord, DataProvider } from '@pankod/refine-core'
import { piscines } from 'src/data/pools'
import { fromRecordToFormData } from 'src/libs/fromRecordToFormData'
import { v4 } from 'uuid'

import { compactObj } from '../libs/filterObj'

type BaseDocument = BaseRecord & { file: { file: File } }

export function antiCorruptionLayerProxy(dataProvider: DataProvider) {
  const proxies = Proxies(dataProvider)
  return new Proxy(dataProvider, {
    get(_, methodName: keyof DataProvider) {
      if (typeof methodName !== 'string') return dataProvider[methodName]
      return function wrappedMethod(params: any) {
        if (!params) return (dataProvider[methodName] as any)(params)

        const { resource } = params
        if (resource.includes('users/edit')) {
          // @ts-ignore
          const proxy = proxies.users
          if (proxy && methodName in proxy) {
            // @ts-ignore
            return proxy[methodName](params)
          }
        }
        if (resource in proxies) {
          // @ts-ignore
          const proxy = proxies[resource]
          if (proxy && methodName in proxy) {
            // @ts-ignore
            return proxy[methodName](params)
          }
        }

        return (dataProvider[methodName] as any)(params)
      }
    },
  })
}

type ProxiesMap = Partial<Record<string, Partial<DataProvider>>>

function Proxies(dataProvider: DataProvider): ProxiesMap {
  return {
    users: userProxy(dataProvider),
    'orders/reassign': reassignProxy(dataProvider),
    'documents/pdf': documentProxy(dataProvider, 'pdf'),
    'documents/vimeo': documentProxy(dataProvider, 'vimeo'),
  }
}

function reassignProxy(dataProvider: DataProvider): Partial<DataProvider> {
  return {
    update(args) {
      return dataProvider.custom!({
        url: args.resource,
        method: 'put',
        payload: args.variables,
      })
    },
  }
}

function userProxy(dataProvider: DataProvider): Partial<DataProvider> {
  return {
    create(args) {
      return dataProvider.create({
        ...args,
        variables: {
          ...addPoolName(args.variables as unknown as UserWrite),
          roles: ['ROLE_COMMERCIAL'],
        },
      })
    },
    update(args) {
      if (typeof args.id === 'string' && args.id.includes('/password')) {
        return dataProvider.update({
          ...args,
        })
      }
      return dataProvider.update({
        ...args,
        variables: addPoolName(args.variables as unknown as UserWrite),
      })
    },
  }
}

function addPoolName(user: UserWrite): UserWrite {
  const pool = piscines.find((piscine) => piscine.code === user.poolRef)
  if (!pool) {
    throw Error(
      `Pas de piscine correspondant au code: ${JSON.stringify(user.poolRef)}`,
    )
  }
  return { ...user, poolName: pool.name }
}

function documentProxy(
  dataProvider: DataProvider,
  type: 'pdf' | 'vimeo',
): Partial<DataProvider> {
  return {
    async getOne(params) {
      const response = await dataProvider.getOne<any>({
        ...params,
        resource: 'documents',
      })
      const { languages } = response.data
      return {
        ...response,
        data: {
          ...response.data,
          languages: languages.length ? languages : null,
        },
      }
    },
    create(params) {
      const { variables, ...rest } = params
      const encodedVars = encodeCreateVars(variables as BaseRecord, type)
      return dataProvider.custom!({
        ...rest,
        method: 'post',
        url: `documents/${type}`,
        payload: encodedVars,
        headers: {
          ...rest?.metaData?.headers,
          'Content-Type': 'multipart/form-data',
        },
      })
    },
    update(params) {
      const { variables, ...rest } = params

      const encodedVars = encodeUpdateVars(variables as BaseRecord, type)

      return dataProvider.custom!({
        ...rest,
        method: 'post',
        url: `documents/${type}/${params.id}`,
        payload: encodedVars,
        headers: {
          ...rest?.metaData?.headers,
          'Content-Type': 'multipart/form-data',
        },
      })
    },
    deleteOne(params) {
      return dataProvider.deleteOne({ ...params, resource: 'documents' })
    },
  }
}

function encodeCreateVars(variables: BaseRecord, type: 'pdf' | 'vimeo') {
  const languages = variables?.languages ? `[${variables.languages}]` : '[]'

  if (type === 'vimeo') {
    const vimeoPayload = {
      ...variables,
      languages,
      fileName: variables.url,
      newId: v4(),
      mimeType: 'video/mp4',
      extension: 'mp4',
    }
    return fromRecordToFormData(compactObj(vimeoPayload))
  }

  const newId = v4()
  const { file } = (variables as BaseDocument).file
  const pdfPayload = {
    newId,
    displayName: variables.displayName,
    fileName: v4() + '.' + file.name.split('.').at(-1),
    size: file.size,
    mimeType: file.type,
    extension: file.name.split('.').at(-1),
    languages,
    file,
  }
  return fromRecordToFormData(compactObj(pdfPayload))
}

function encodeUpdateVars(variables: BaseRecord, type: 'pdf' | 'vimeo') {
  const languages = variables?.languages ? `[${variables.languages}]` : '[]'
  if (type === 'vimeo') {
    const values: BaseRecord = compactObj({ ...variables, languages })
    return fromRecordToFormData({
      ...values,

      fileName: values.url,
    })
  }
  const file = (variables as BaseDocument)?.file?.file

  const pdfPayload = {
    displayName: variables.displayName,
    languages,
    file,
  }
  const values = compactObj(pdfPayload)
  return fromRecordToFormData(values)
}
