import { ResourcePathEnum } from '@easyguide/types'

import { CreateView } from './Create'
import { EditView } from './Edit'
import { ListView } from './List'

export const user = {
  name: ResourcePathEnum.users,
  create: CreateView,
  list: ListView,
  edit: EditView,
}
