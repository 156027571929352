import { piscines } from 'src/data/pools'
import { makeInputs } from 'src/ui-kit'

const Input = makeInputs()

export function FormInputs() {
  return (
    <>
      <Input.Text label="Nom" name="firstname" required />
      <Input.Text label="Prénom" name="lastname" required />
      <Input.Text type="email" label="Email" name="email" required />
      <Input.Select
        label="Modèle de piscine"
        name="poolRef"
        options={piscines}
        optionKey="designation"
        optionValue="code"
        required
      />
    </>
  )
}
