import { LogoOnAmbiant } from '@easyguide/design'
import type { TitleProps } from '@pankod/refine-core'
import { Link } from 'react-router-dom'

export function Title(_: TitleProps) {
  return (
    <Link to="/">
      <LogoOnAmbiant style={{ width: '100%', maxHeight: '96px' }} />
    </Link>
  )
}
