// source: https://docs.google.com/spreadsheets/d/1cRbBC34cnyWTfSRaCdJA3Q646soIuTKW/edit#gid=408962642

export type Pool = {
  code: string
  designation: string
  name: string
}

export const piscines: Pool[] = [
  {
    code: 'BARB03',
    designation: 'Barbara 03',
    name: 'Piscine Barbara',
  },
  {
    code: 'BARB07',
    designation: 'Barbara 07',
    name: 'Piscine Barbara',
  },
  {
    code: 'BARB08',
    designation: 'Barbara 08',
    name: 'Piscine Barbara',
  },
  {
    code: 'BARB09',
    designation: 'Barbara 09',
    name: 'Piscine Barbara',
  },
  {
    code: 'BARB1',
    designation: 'Barbara I',
    name: 'Piscine Barbara',
  },
  {
    code: 'BARB2',
    designation: 'Barbara II',
    name: 'Piscine Barbara',
  },
  {
    code: 'BARB3',
    designation: 'Barbara III',
    name: 'Piscine Barbara',
  },
  {
    code: 'BARB4',
    designation: 'Barbara IV',
    name: 'Piscine Barbara',
  },
  {
    code: 'BARB5',
    designation: 'Barbara V',
    name: 'Piscine Barbara',
  },
  {
    code: 'BARB6',
    designation: 'Barbara VI',
    name: 'Piscine Barbara',
  },
  {
    code: 'BARBK6',
    designation: 'Barbara K6',
    name: 'Piscine Barbara',
  },
  {
    code: 'BARBM03',
    designation: 'Barbara 03 Mini',
    name: 'Piscine Barbara',
  },
  {
    code: 'BARBS6',
    designation: 'Barbara S6',
    name: 'Piscine Barbara',
  },
  {
    code: 'CEL05',
    designation: 'Céline 05',
    name: 'Piscine Céline',
  },
  {
    code: 'CEL06',
    designation: 'Céline 06',
    name: 'Piscine Céline',
  },
  {
    code: 'CEL07',
    designation: 'Céline 07',
    name: 'Piscine Céline',
  },
  {
    code: 'CEL08',
    designation: 'Céline 08',
    name: 'Piscine Céline',
  },
  {
    code: 'CEL09',
    designation: 'Céline 09',
    name: 'Piscine Céline',
  },
  {
    code: 'CEL10',
    designation: 'Céline 10',
    name: 'Piscine Céline',
  },
  {
    code: 'CEL11',
    designation: 'Céline 11',
    name: 'Piscine Céline',
  },
  {
    code: 'CLEA1410',
    designation: 'Cléa 1 410',
    name: 'Piscine Cléa',
  },
  {
    code: 'CLEA1460',
    designation: 'Cléa 1 460',
    name: 'Piscine Cléa',
  },
  {
    code: 'CLEA2410',
    designation: 'Cléa 2 410',
    name: 'Piscine Cléa',
  },
  {
    code: 'CLEA2460',
    designation: 'Cléa 2 460',
    name: 'Piscine Cléa',
  },
  {
    code: 'CLEA3410',
    designation: 'Cléa 3 410',
    name: 'Piscine Cléa',
  },
  {
    code: 'ELSA10415',
    designation: 'Elsa 10 415',
    name: 'Piscine Elsa',
  },
  {
    code: 'ELSA12357',
    designation: 'Elsa 12 357',
    name: 'Piscine Elsa',
  },
  {
    code: 'ELSA6357',
    designation: 'Elsa 6 357',
    name: 'Piscine Elsa',
  },
  {
    code: 'ELSA7357',
    designation: 'Elsa 7 357',
    name: 'Piscine Elsa',
  },
  {
    code: 'ELSA8357',
    designation: 'Elsa 8 357',
    name: 'Piscine Elsa',
  },
  {
    code: 'ELSA8385',
    designation: 'Elsa 8 385',
    name: 'Piscine Elsa',
  },
  {
    code: 'ELSA8415',
    designation: 'Elsa 8 415',
    name: 'Piscine Elsa',
  },
  {
    code: 'ELSA9385',
    designation: 'Elsa 9 385',
    name: 'Piscine Elsa',
  },
  {
    code: 'ELSA9415',
    designation: 'Elsa 9 415',
    name: 'Piscine Elsa',
  },
  {
    code: 'EMILIE03',
    designation: 'Emilie 03',
    name: 'Piscine Emilie mini',
  },
  {
    code: 'EMILIE06',
    designation: 'Emilie 06',
    name: 'Piscine Emilie',
  },
  {
    code: 'EMILIE07',
    designation: 'Emilie 07',
    name: 'Piscine Emilie',
  },
  {
    code: 'EMILIE08',
    designation: 'Emilie 08',
    name: 'Piscine Emilie',
  },
  {
    code: 'EMILIE09',
    designation: 'Emilie 09',
    name: 'Piscine Emilie',
  },
  {
    code: 'EMILIE10',
    designation: 'Emilie 10',
    name: 'Piscine Emilie',
  },
  {
    code: 'EMILIECN',
    designation: 'Emilie CN',
    name: 'Piscine Emilie',
  },
  {
    code: 'EVA07',
    designation: 'Eva 07',
    name: 'Piscine Eva',
  },
  {
    code: 'EVA08',
    designation: 'Eva 08',
    name: 'Piscine Eva',
  },
  {
    code: 'EVA09',
    designation: 'Eva 09',
    name: 'Piscine Eva',
  },
  {
    code: 'LOLA',
    designation: 'Lola',
    name: 'Piscine Lola mini',
  },
  {
    code: 'LUNA2296',
    designation: 'Luna 2 296',
    name: 'Piscine Luna',
  },
  {
    code: 'LUNA3296',
    designation: 'Luna 3 296',
    name: 'Piscine Luna',
  },
  {
    code: 'MILA2486',
    designation: 'MILA2 486',
    name: 'Piscine Mila',
  },
  {
    code: 'MILA2510',
    designation: 'MILA2 510',
    name: 'Piscine Mila',
  },
  {
    code: 'MILA2579',
    designation: 'MILA2 579',
    name: 'Piscine Mila',
  },
  {
    code: 'MILA2672',
    designation: 'MILA2 672',
    name: 'Piscine Mila',
  },
  {
    code: 'NINA2382',
    designation: 'NINA2 382',
    name: 'Piscine Nina',
  },
  {
    code: 'NINA2475',
    designation: 'NINA2 475',
    name: 'Piscine Nina',
  },
  {
    code: 'NINA2568',
    designation: 'NINA2 568',
    name: 'Piscine Nina',
  },
  {
    code: 'NINA2L382',
    designation: 'NINA2L 382',
    name: 'Piscine Nina',
  },
  {
    code: 'NINA2L475',
    designation: 'NINA2L 475',
    name: 'Piscine Nina',
  },
  {
    code: 'NINA2L568',
    designation: 'NINA2L 568',
    name: 'Piscine Nina',
  },
  {
    code: 'NINA3S382',
    designation: 'NINA3S 382',
    name: 'Piscine Nina',
  },
  {
    code: 'NINA3S475',
    designation: 'NINA3S 475',
    name: 'Piscine Nina',
  },
  {
    code: 'NINA3S568',
    designation: 'NINA3S 568',
    name: 'Piscine Nina',
  },
  {
    code: 'NINA3S647',
    designation: 'NINA3S 647',
    name: 'Piscine Nina',
  },
  {
    code: 'NINA3568',
    designation: 'NINA3 568',
    name: 'Piscine Nina',
  },
  {
    code: 'NINA3647',
    designation: 'NINA3 647',
    name: 'Piscine Nina',
  },
  {
    code: 'NINA3708',
    designation: 'NINA3 708',
    name: 'Piscine Nina',
  },
  {
    code: 'NINA3801',
    designation: 'NINA3 801',
    name: 'Piscine Nina',
  },
  {
    code: 'OV0237',
    designation: 'Olivia 0 237',
    name: 'Piscine Olivia mini',
  },
  {
    code: 'OV1237',
    designation: 'Olivia 1 237',
    name: 'Piscine Olivia',
  },
  {
    code: 'OV1296',
    designation: 'Olivia 1 296',
    name: 'Piscine Olivia',
  },
  {
    code: 'OV1357',
    designation: 'Olivia 1 357',
    name: 'Piscine Olivia',
  },
  {
    code: 'OV1415',
    designation: 'Olivia 1 415',
    name: 'Piscine Olivia',
  },
  {
    code: 'OV2296',
    designation: 'Olivia 2 296',
    name: 'Piscine Olivia',
  },
  {
    code: 'OV2357',
    designation: 'Olivia 2 357',
    name: 'Piscine Olivia',
  },
  {
    code: 'OV2357CN',
    designation: 'Olivia 2 357 Couloir de Nage',
    name: 'Piscine Olivia',
  },
  {
    code: 'OV2385',
    designation: 'Olivia 2 385',
    name: 'Piscine Olivia',
  },
  {
    code: 'OV2415',
    designation: 'Olivia 2 415',
    name: 'Piscine Olivia',
  },
  {
    code: 'OV2475',
    designation: 'Olivia 2 475',
    name: 'Piscine Olivia',
  },
  {
    code: 'OV2534',
    designation: 'Olivia 2 534',
    name: 'Piscine Olivia',
  },
  {
    code: 'OV2712',
    designation: 'Olivia 2 712',
    name: 'Piscine Olivia',
  },
  {
    code: 'OV31065',
    designation: 'Olivia 3 1065',
    name: 'Piscine Olivia',
  },
  {
    code: 'OV3475',
    designation: 'Olivia 3 475',
    name: 'Piscine Olivia',
  },
  {
    code: 'OV3712',
    designation: 'Olivia 3 712',
    name: 'Piscine Olivia',
  },
  {
    code: 'OV3800',
    designation: 'Olivia 3 800',
    name: 'Piscine Olivia',
  },
  {
    code: 'OV3890',
    designation: 'Olivia 3 890',
    name: 'Piscine Olivia',
  },
  {
    code: 'OV41065',
    designation: 'Olivia 4 1065',
    name: 'Piscine Olivia',
  },
  {
    code: 'OV4890',
    designation: 'Olivia 4 890',
    name: 'Piscine Olivia',
  },
  {
    code: 'SARA03',
    designation: 'Sara 03',
    name: 'Piscine Sara Mini',
  },
  {
    code: 'SARA05',
    designation: 'Sara 05',
    name: 'Piscine Sara',
  },
  {
    code: 'SARA06',
    designation: 'Sara 06',
    name: 'Piscine Sara',
  },
  {
    code: 'SARA07',
    designation: 'Sara 07',
    name: 'Piscine Sara',
  },
  {
    code: 'SARA08',
    designation: 'Sara 08',
    name: 'Piscine Sara',
  },
  {
    code: 'SARA09',
    designation: 'Sara 09',
    name: 'Piscine Sara',
  },
  {
    code: 'SARA10',
    designation: 'Sara 10',
    name: 'Piscine Sara',
  },
  {
    code: 'SARA11',
    designation: 'Sara 11',
    name: 'Piscine Sara',
  },
  {
    code: 'SARACN',
    designation: 'Sara Couloir de Nage',
    name: 'Piscine Sara',
  },
  {
    code: 'SOFIA06',
    designation: 'Sofia 06',
    name: 'Piscine Sofia',
  },
  {
    code: 'SOFIA07',
    designation: 'Sofia 07',
    name: 'Piscine Sofia',
  },
  {
    code: 'SOFIA08',
    designation: 'Sofia 08',
    name: 'Piscine Sofia',
  },
  {
    code: 'SOFIA09',
    designation: 'Sofia 09',
    name: 'Piscine Sofia',
  },
]
// source: https://docs.google.com/spreadsheets/d/1cRbBC34cnyWTfSRaCdJA3Q646soIuTKW/edit#gid=408962642
