import { UploadOutlined } from '@ant-design/icons'
import type { RcFile, UploadProps } from '@pankod/refine-antd'
import {
  Button,
  Modal,
  notification,
  Space,
  Typography,
  Upload,
} from '@pankod/refine-antd'
import { useInvalidate } from '@pankod/refine-core'
import type { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { useHttpClient } from 'src/adapters/HTTPClient'
import { utils, read } from 'xlsx'

type CsvImporterProps = {
  resource: string
  modalTitle: string
  formatter: (data: any) => any
}

export function CSVtoJSON(props: CsvImporterProps) {
  const { resource, formatter, modalTitle } = props
  const invalidate = useInvalidate()
  const navigate = useNavigate()

  const [data, setData] = useState<any>([])
  const [open, setOpen] = useState<boolean>(false)
  const [fileList, setFileList] = useState<RcFile[]>([])

  const httpClient = useHttpClient()

  function successMessage(response: AxiosResponse) {
    return (
      <Space direction="vertical" size="small">
        <Typography.Text type="success">
          Succès: {response.data.success}
        </Typography.Text>
        <Typography.Text type="danger">
          Erreurs: {response.data.failed.length}
        </Typography.Text>
      </Space>
    )
  }

  function prepareFile(file: RcFile) {
    setFileList([file])
    const selectedFile = file
    if (selectedFile) {
      const reader = new FileReader()
      reader.onload = (evt) => {
        const fileData = evt.target?.result
        const workbook = read(fileData, { type: 'binary' })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const json = utils.sheet_to_json(worksheet)
        setData(json)
      }
      reader.readAsBinaryString(selectedFile)
    }
  }

  function sendFile() {
    httpClient
      .put(resource, formatter(data))
      .then((response) => {
        notification.success({
          message: 'Import effectué avec succès',
          description: successMessage(response),
        })
        setOpen(false)
        navigate('/partners')
      })
      .catch((error) => {
        notification.error({
          message: "Une erreur s'est produite",
          description: `${
            error.response.data.detail ?? error.response.data.statusText
          }`,
        })
      })
      .finally(() => {
        setFileList([])
        invalidate({
          resource: 'partners',
          invalidates: ['list'],
        })
      })
  }

  function SendFileButton() {
    if (fileList.length === 0) return <></>
    return (
      <Button type="primary" onClick={sendFile}>
        Importer
      </Button>
    )
  }

  const importProps: UploadProps = {
    onRemove() {
      setFileList([])
    },
    beforeUpload(file) {
      prepareFile(file)
      return false
    },
    itemRender(originNode) {
      return originNode
    },
    fileList,
  }

  return (
    <>
      <Button type="primary" onClick={() => setOpen(true)}>
        {modalTitle}
      </Button>
      <Modal
        visible={open}
        title="Importer un fichier"
        onCancel={() => setOpen(false)}
        footer={[
          <Button key="back" onClick={() => setOpen(false)}>
            Annuler
          </Button>,
          <SendFileButton />,
        ]}
      >
        <Upload {...importProps}>
          <Space>
            <Button icon={<UploadOutlined />}>Choisir un fichier</Button>
            <Typography.Text>Accepte les formats csv / xlsx</Typography.Text>
          </Space>
        </Upload>
      </Modal>
    </>
  )
}

export default CSVtoJSON
