import { DownloadOutlined } from '@ant-design/icons'
import { colors } from '@easyguide/design/tokens'

import {
  List,
  Tabs,
  Button,
  Card,
  DatePicker,
  Select,
  Input,
  Space,
  Typography,
} from '@pankod/refine-antd'
import { useExport } from '@pankod/refine-core'
import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import './index.less'
import { useQuery } from 'react-query'
import { useHttpClient } from 'src/adapters/HTTPClient'

import { CalculatedStats, TableStat } from './ComponentStats/CalculatedStats'
import { exportCustomStats } from './ComponentStats/exportCustomStats'

export function ListView() {
  const [filterField, setFilterField] = useState('')
  const [partnerCode, setPartnerCode] = useState<string | undefined>(undefined)
  const httpClient = useHttpClient()
  const dateFormat = 'DD/MM/YYYY'
  const [lastDate, setLastDate] = useState(dayjs())
  const [firstDate, setFirstDate] = useState(dayjs(lastDate).subtract(7, 'day'))

  const { RangePicker } = DatePicker
  const { data: globalStats } = useQuery(['statistics', 'global'], async () => {
    const data = await httpClient.get('statistics/export/global')
    return data
  })

  const { isLoading } = useExport<any>({
    resourceName: 'statistics',
    exportOptions: {
      filename: `statistiques${
        filterField !== '' ? `_${filterField}` : '_'
      }_${new Date().toLocaleDateString('fr')}`,
    },
    filters: [
      {
        field: 'eventKey',
        operator: 'eq',
        value: filterField,
      },
    ],
    mapData(item) {
      return mapData(item)
    },
  })
  function exportStats() {
    if (exportedStats !== undefined) {
      exportCustomStats(exportedStats)
    }
  }

  const { triggerExport, isLoading: rawLoading } = useExport<any>({
    resourceName: 'statistics',
    exportOptions: {
      filename: `statistiques${
        filterField !== '' ? `_${filterField}` : '_'
      }_${new Date().toLocaleDateString('fr')}`,
    },
    filters: [
      {
        field: 'eventKey',
        operator: 'eq',
        value: filterField,
      },
      {
        field: 'partnercode',
        operator: 'contains',
        value: partnerCode,
      },
      {
        field: 'recordedAt',
        operator: 'lte',
        value: new Date(dayjs(lastDate).endOf('day').format()),
      },
      {
        field: 'recordedAt',
        operator: 'gte',
        value: new Date(dayjs(firstDate).startOf('day').format()),
      },
    ],
    mapData(item) {
      return mapData(item)
    },
    onError() {
      console.log(
        'La plage sélectionnée contient trop de données pour être traitée, merci de réduire la période.',
      )
    },
  })

  const exportedStats = useMemo(() => {
    if (globalStats?.data !== undefined) {
      return {
        firstOpen: globalStats.data.firstOpen,
        callSupport: globalStats.data.callSupport,
        stepValidated: globalStats.data.stepValidated,
        vimeo: globalStats.data.vimeo,
        pdf: globalStats.data.pdf,
        precautionaryWarnings: globalStats.data.precautionaryWarnings,
        safetyRules: globalStats.data.safetyRules,
        info: globalStats.data.info,
      }
    }
  }, [globalStats])

  function setRange(dates: string[]) {
    setFirstDate(dayjs(dates[0], dateFormat))
    setLastDate(dayjs(dates[1], dateFormat))
  }
  function handleChange(value: string) {
    setFilterField(value)
  }

  function changePartnerfilter(value: any) {
    setPartnerCode(value.target.value)
  }

  const filterDates = useMemo(() => {
    return { start: firstDate.toDate(), end: lastDate.toDate() }
  }, [firstDate, lastDate])

  const filterPartner = useMemo(() => {
    return { partner: partnerCode }
  }, [partnerCode])

  return (
    <List title="Statistiques">
      <>
        <div className="customStatContainer">
          <Card
            className={'customCard'}
            headStyle={{
              background: colors.ambient,
              color: colors.background,
            }}
            title="Statistiques globales"
            style={{ width: '100%' }}
            extra={
              <Button onClick={exportStats} icon={<DownloadOutlined />}>
                Exporter
              </Button>
            }
          >
            <Card
              className={'customCard customCard-stats'}
              title="Utilisation moblie"
              headStyle={{ textAlign: 'center' }}
            >
              <CalculatedStats
                title="Premier lancement"
                value={exportedStats?.firstOpen}
              />
            </Card>
            <Card
              className={'customCard customCard-stats'}
              title="Support technique"
              headStyle={{ textAlign: 'center' }}
            >
              <CalculatedStats
                title="Appel support"
                value={exportedStats?.callSupport}
              />
              <CalculatedStats
                title="WatRDV"
                value={exportedStats?.stepValidated}
              />
            </Card>
            <Card
              className={'customCard customCard-stats'}
              title="Contenus visionnés"
              headStyle={{ textAlign: 'center' }}
            >
              <CalculatedStats title="Vidéos" value={exportedStats?.vimeo} />
              <CalculatedStats title="PDF" value={exportedStats?.pdf} />
            </Card>
            <Card
              className={'customCard customCard-stats'}
              title="Ecrans visionnés"
              headStyle={{ textAlign: 'center' }}
            >
              <CalculatedStats
                title="Avertissements"
                value={exportedStats?.precautionaryWarnings}
              />
              <CalculatedStats
                title="Règles de sécurité"
                value={exportedStats?.safetyRules}
              />
              <CalculatedStats title="Mes infos" value={exportedStats?.info} />
            </Card>
          </Card>
        </div>
        <Card
          title={
            isLoading
              ? 'Données brutes (téléchargement en cours)'
              : 'Données brutes'
          }
          headStyle={{ background: colors.ambient, color: colors.background }}
          extra={
            <Space>
              <RangePicker
                format={dateFormat}
                onChange={(date, dateString) => setRange(dateString)}
                value={[firstDate, lastDate]}
              />
              <Select
                defaultValue={filterField}
                style={{ width: 180 }}
                onChange={(value) => handleChange(value)}
              >
                <Select.Option value="">Toutes les données</Select.Option>
                {optionsList.map((option) => {
                  return (
                    <Select.Option value={option.key}>
                      {option.name}
                    </Select.Option>
                  )
                })}
              </Select>
              <Button
                onClick={triggerExport}
                loading={rawLoading}
                icon={<DownloadOutlined />}
              />
            </Space>
          }
        >
          <Space direction="vertical">
            <Typography.Title level={4} style={{ margin: 0 }}>
              Rechercher un code partenaire
            </Typography.Title>
            <Input
              style={{ width: '500px' }}
              onChange={(value) => changePartnerfilter(value)}
              placeholder="Rechercher un code partenaire"
            />
          </Space>
          <Tabs defaultActiveKey={'firstOpen'} style={{ overflow: 'scroll' }}>
            {optionsList.map((option) => {
              return (
                <Tabs.TabPane tab={option.name} key={option.key}>
                  <TableStat
                    filter={{ ...filterPartner, event: option.key }}
                    dates={filterDates}
                  />
                </Tabs.TabPane>
              )
            })}
          </Tabs>
        </Card>
      </>
    </List>
  )
}

const optionsList = [
  { key: 'firstOpen', name: 'Premiers lancements' },
  { key: 'stepValidated', name: 'Etapes validées' },
  { key: 'stepTaskEdited', name: 'Statut des taches' },
  { key: 'callSupport', name: 'Appels au support' },
  { key: 'viewItem', name: 'Contenus visionnés' },
  { key: 'viewScreen', name: 'Ecrans visionnés' },
]

function mapData(item: any) {
  return {
    deviceId: item.deviceId,
    customerNumber: item.customerNumber,
    userId: item.userId,
    recordedAt: item.recordedAt,
    eventKey: item.eventKey,
    stepIndex: item.stepIndex,
    stepId: item.stepId,
    stepName: item.stepName,
    taskIndex: item.taskIndex,
    taskId: item.taskId,
    taskName: item.taskName,
    newState: item.newState,
    itemId: item.itemId,
    itemName: item.itemName,
    itemType: item.itemType,
    screenClass: item.screenClass,
    screenName: item.screenName,
    partnerCode: item.partnerCode,
  }
}
