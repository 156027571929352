export function filterObj<T extends Record<string, any>>(
  obj: T,
  predicate: (k: any) => any,
): Partial<T> {
  const result: Partial<T> = {}

  for (const key in obj) {
    const value = obj[key]
    if (predicate(value)) {
      result[key] = value
    }
  }

  return result
}

/** filters out falsy values from object */
export function compactObj<T extends Record<string, any>>(obj: T): Partial<T> {
  return filterObj(obj, Boolean)
}
