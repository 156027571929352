import type { UserRead, Partner } from '@easyguide/types'
import { ResourcePathEnum } from '@easyguide/types'
import { cleanHydraId } from '@nartex/api-platform'
import { List, Table, useTable, Space } from '@pankod/refine-antd'
import CSVtoJSON from 'src/components/CsvImporter'
import { EditButton } from 'src/components/ResourceButtons'

export function ListView() {
  const { tableProps } = useTable<Partner>({
    resource: ResourcePathEnum.partners,
    initialCurrent: 1,
    initialPageSize: 10,
  })

  function formatPartner(data: any) {
    return [...data]
  }

  return (
    <List
      title="Partenaires"
      headerButtons={(headerParams) => {
        const { defaultButtons } = headerParams
        return (
          <>
            <CSVtoJSON
              resource="partners/import/batch"
              modalTitle="Importer des partenaires"
              formatter={formatPartner}
            />
            {defaultButtons}
          </>
        )
      }}
    >
      <Table<Partner> {...tableProps} rowKey={(user) => user.id}>
        <Table.Column
          dataIndex="firstname"
          title="Nom"
          sorter={{
            compare: function (a: Partner, b: Partner) {
              return a.firstname.localeCompare(b.firstname)
            },
          }}
        />
        <Table.Column
          dataIndex="lastname"
          title="Prénom"
          sorter={{
            compare: function (a: UserRead, b: UserRead) {
              return a.lastname.localeCompare(b.lastname)
            },
          }}
        />
        <Table.Column dataIndex={'society'} title="Société" />
        <Table.Column dataIndex={'phoneNumber'} title="Téléphone" />
        <Table.Column dataIndex={'email'} title={'Email'} />
        <Table.Column dataIndex={'country'} title="Pays" />
        <Table.Column dataIndex={'code'} title="Code Partenaire" />
        <Table.Column<UserRead>
          dataIndex="actions"
          render={(_, record) => {
            return (
              <Space style={{ justifyContent: 'flex-end', width: '100%' }}>
                <EditButton recordItemId={cleanHydraId(record.id)} />
              </Space>
            )
          }}
        />
      </Table>
    </List>
  )
}
