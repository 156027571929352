import type { UserWrite } from '@easyguide/types'
import { useForm, Form, Create } from '@pankod/refine-antd'

import { FormInputs } from './FormTemplate'

export function CreateView() {
  const { formProps, saveButtonProps } = useForm<UserWrite>({
    redirect: 'list',
  })

  return (
    <Create
      saveButtonProps={saveButtonProps}
      title={`Création d'un utilisateur`}
    >
      <Form {...formProps} layout="vertical">
        <FormInputs />
      </Form>
    </Create>
  )
}
