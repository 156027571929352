import { Logo } from '@easyguide/design'
import { AntdLayout, Card, Form, Input, Button } from '@pankod/refine-antd'
import { useLogin } from '@pankod/refine-core'
import './LoginPage.less'

export type ILoginForm = {
  username: string
  password: string
  remember: boolean
}

export function LoginPage() {
  const [form] = Form.useForm<ILoginForm>()

  const { mutate: login } = useLogin<ILoginForm>()

  return (
    <AntdLayout className="login-page">
      <Card
        className="container"
        cover={<Logo />}
        headStyle={{ borderBottom: 0 }}
        style={{ padding: '20px' }}
      >
        <Form<ILoginForm>
          layout="vertical"
          form={form}
          onFinish={(values) => {
            login(values)
          }}
          requiredMark={false}
        >
          <Form.Item name="username" label="Email" rules={[{ required: true }]}>
            <Input size="large" placeholder="exemple@waterair.fr" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Mot de passe"
            rules={[{ required: true }]}
            style={{ marginBottom: '24px' }}
          >
            <Input type="password" placeholder="●●●●●●●●" size="large" />
          </Form.Item>
          <Button type="primary" size="large" htmlType="submit" block>
            Connexion
          </Button>
        </Form>
      </Card>
    </AntdLayout>
  )
}
