import type { UserRead } from '@easyguide/types'
import { Edit, Form, useForm } from '@pankod/refine-antd'
import { useNavigate } from 'react-router'

import { FormInputs } from './FormTemplate'

export function EditView() {
  const { formProps, saveButtonProps, queryResult, id } = useForm<UserRead>({
    action: 'edit',
    redirect: 'list',
  })

  const navigate = useNavigate()

  function redirectList() {
    navigate('/users')
  }

  return (
    <Edit
      title={`Édition : ${editUserTitle(queryResult?.data?.data)}`}
      canDelete={true}
      saveButtonProps={saveButtonProps}
      recordItemId={id}
      deleteButtonProps={{ onSuccess: redirectList }}
    >
      <Form {...formProps} layout="vertical">
        <FormInputs />
      </Form>
    </Edit>
  )
}

function editUserTitle(user: UserRead | undefined) {
  return `${user?.firstname} ${user?.lastname}`
}
