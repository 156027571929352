import { ResourcePathEnum } from '@easyguide/types/api'

import { CreateView } from './Create'
import { EditView } from './Edit'

import { ListView } from './List'

export const pdfDocuments = {
  name: `${ResourcePathEnum.documents}/pdf`,
  create() {
    return <CreateView type="pdf" />
  },
  edit() {
    return <EditView type="pdf" />
  },
  list() {
    return <ListView defaultValue="application/pdf" />
  },
}

export const vimeoDocuments = {
  name: `${ResourcePathEnum.documents}/vimeo`,
  create() {
    return <CreateView type="vimeo" />
  },
  edit() {
    return <EditView type="vimeo" />
  },
  list() {
    return <ListView defaultValue="video/mp4" />
  },
}

export const documents = [
  {
    name: ResourcePathEnum.documents,
    list() {
      return <ListView defaultValue="application/pdf" />
    },
  },
  pdfDocuments,
  vimeoDocuments,
]
