import { UploadOutlined } from '@ant-design/icons'
import type { RcFile, UploadProps } from '@pankod/refine-antd'
import { Button, Form, Space, Typography, Upload } from '@pankod/refine-antd'

import { useState } from 'react'
import { read, utils } from 'xlsx'

type CsvPickerProps = {
  formatter: (data: any) => any
  setValue: (value: {}) => void
}

export function CsvPicker(props: CsvPickerProps) {
  const { formatter, setValue } = props
  const [fileList, setFileList] = useState<RcFile[]>([])

  function prepareFile(file: RcFile) {
    setFileList([file])
    const selectedFile = file
    if (selectedFile) {
      const reader = new FileReader()
      reader.onload = async (evt) => {
        const fileData = evt.target?.result
        const workbook = read(fileData, { type: 'binary' })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const json = await utils.sheet_to_json(worksheet)
        const formattedJson = formatter(json)
        setValue({ orderIds: formattedJson })
      }
      reader.readAsBinaryString(selectedFile)
    }
  }

  const importProps: UploadProps = {
    onRemove() {
      setFileList([])
    },
    beforeUpload(file) {
      prepareFile(file)
      return false
    },
    itemRender(originNode) {
      return originNode
    },
    fileList,
  }

  return (
    <>
      <Form.Item label={'Fichier csv'} name={'orderIds'} required>
        <Upload {...importProps}>
          <Space>
            <Button icon={<UploadOutlined />}>Choisir un fichier</Button>
            <Typography.Text>Accepte les formats csv / xlsx</Typography.Text>
          </Space>
        </Upload>
      </Form.Item>
    </>
  )
}
