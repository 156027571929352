import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export function useI18nProvider() {
  const { t, i18n } = useTranslation()

  return useMemo(() => {
    return {
      translate(key: string, params: object) {
        return t(key, params)
      },
      changeLocale(lang: string) {
        return i18n.changeLanguage(lang)
      },
      getLocale() {
        return i18n.language
      },
    }
  }, [t, i18n])
}
