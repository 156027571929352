import { ResourcePathEnum } from '@easyguide/types'
import {
  AntdLayout,
  useMenu,
  Menu as RefineMenu,
  Icons,
} from '@pankod/refine-antd'
import { useLogout, useTranslate } from '@pankod/refine-core'
import classnames from 'classnames'
import type { PropsWithChildren } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import './Menu.less'
import { Title } from './Title'

const menuItems = [
  // { name: '', Icon: Icons.DashboardOutlined, title: 'dashboard.title' },
  {
    name: ResourcePathEnum.users,
    title: 'Utilisateurs',
    Icon: Icons.UserOutlined,
  },
  {
    name: ResourcePathEnum.partners,
    title: 'Partenaires',
    Icon: Icons.BookOutlined,
  },
  {
    name: 'reassign',
    title: 'Reassigner',
    Icon: Icons.AccountBookFilled,
  },
  {
    name: ResourcePathEnum.statistics,
    title: 'Statistiques',
    Icon: Icons.LineChartOutlined,
  },
  {
    name: ResourcePathEnum.documents,
    title: 'Contenus',
    Icon: Icons.FolderOutlined,
  },
]

export function Menu() {
  const translate = useTranslate()
  const { selectedKey } = useMenu()
  const { mutate: logout } = useLogout()

  return (
    <MenuLayout>
      <RefineMenu
        className="NxMenu"
        selectedKeys={[selectedKey]}
        mode="inline"
        onClick={(event) => {
          const { key } = event
          if (key === 'logout') {
            logout()
          }
        }}
      >
        {menuItems.map((menuItem) => {
          const { name, Icon, title } = menuItem
          const path = `/${name}`
          console.log('selectedKey', selectedKey, name)
          const isActive = selectedKey.includes(path)
          console.log('isActive', isActive)
          return (
            <RefineMenu.Item
              key={path}
              icon={<Icon />}
              className={classnames(
                'NxMenuItem',
                isActive ? '-active ant-menu-item-selected' : '',
              )}
            >
              <Link to={path}>
                {isActive && '> '}
                {translate(title ?? `${name}.name`)}
              </Link>
            </RefineMenu.Item>
          )
        })}

        <RefineMenu.Item
          key="logout"
          icon={<Icons.LogoutOutlined />}
          className={classnames('NxMenuItem', '-logoutButton')}
        >
          {translate('buttons.logout')}
        </RefineMenu.Item>
      </RefineMenu>
    </MenuLayout>
  )
}

export function MenuLayout(props: PropsWithChildren<{}>) {
  const { children } = props
  const [collapsed, setCollapsed] = useState<boolean>(false)

  return (
    <AntdLayout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
      className="NxLayoutSider"
    >
      <Title collapsed={collapsed} />

      {children}
    </AntdLayout.Sider>
  )
}
