import { colors } from '@easyguide/design'
import { Tag } from '@pankod/refine-antd'

type StyledRoleProps = {
  role: string
}

export function StyledRole(props: StyledRoleProps) {
  const { role } = props
  let background
  let name
  switch (role) {
    case 'ROLE_ADMIN':
      background = colors.accentVariant
      name = 'Admin'
      break
    case 'ROLE_COMMERCIAL':
      background = colors.accent
      name = 'Commercial'
      break
    case 'ROLE_USER':
      background = colors.success
      name = 'Utilisateur'
      break
    default:
      background = colors.surface
      name = ''
  }
  return <Tag color={background}>{name}</Tag>
}
