export const eventKeys = {
  firstOpen: ['deviceId', 'recordedAt', 'partnerCode'],
  stepValidated: [
    'deviceId',
    'customerNumber',
    'recordedAt',
    'stepIndex',
    'stepId',
    'stepName',
    'userId',
    'partnerCode',
  ],
  stepTaskEdited: [
    'deviceId',
    'customerNumber',
    'recordedAt',
    'stepIndex',
    'stepId',
    'stepName',
    'taskIndex',
    'taskId',
    'taskName',
    'newState',
    'userId',
    'partnerCode',
  ],
  callSupport: [
    'deviceId',
    'customerNumber',
    'recordedAt',
    'userId',
    'partnerCode',
  ],
  viewItem: [
    'deviceId',
    'customerNumber',
    'recordedAt',
    'itemId',
    'itemName',
    'itemType',
    'userId',
    'partnerCode',
  ],
  viewScreen: [
    'deviceId',
    'customerNumber',
    'recordedAt',
    'screenClass',
    'screenName',
    'userId',
    'stepIndex',
    'stepId',
    'stepName',
    'partnerCode',
  ],
}
