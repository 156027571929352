import React from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClientProvider, QueryClient } from 'react-query'
import { Env } from 'src/adapters/Env'

import { App } from './App'
import { setup } from './setup'
import './i18n'

const queryClient = new QueryClient()

const env = Env()
setup(env)

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App env={env} />
    </QueryClientProvider>
  </React.StrictMode>,
)
