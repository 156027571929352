import { ResourcePathEnum } from '@easyguide/types'
import type { DocumentRead } from '@easyguide/types/api'
import { cleanHydraId } from '@nartex/api-platform'
import {
  useTable,
  List,
  Table,
  Space,
  Tabs,
  CreateButton,
  Typography,
} from '@pankod/refine-antd'
import { useNavigate } from 'react-router'
import { EditButton } from 'src/components/ResourceButtons'

type OptionKey = 'application/pdf' | 'video/mp4'

export type CountryOption = {
  key: OptionKey
  name: string
}

const optionsList: CountryOption[] = [
  { key: 'application/pdf', name: 'Documents PDF' },
  { key: 'video/mp4', name: 'Tutoriels vidéo' },
]

type Props = {
  defaultValue: 'application/pdf' | 'video/mp4'
}
export function ListView(props: Props) {
  const { defaultValue } = props
  const navigate = useNavigate()
  return (
    <List
      title="Contenus - Entretien de la piscine (étape 11)"
      canCreate
      headerButtons={() => {
        return (
          <>
            <CreateButton resourceNameOrRouteName="documents/pdf">
              Ajouter PDF
            </CreateButton>
            <CreateButton resourceNameOrRouteName="documents/vimeo">
              Ajouter Video
            </CreateButton>
          </>
        )
      }}
    >
      <Tabs
        defaultActiveKey={defaultValue}
        style={{ overflow: 'scroll' }}
        onChange={(val) => {
          if (val === 'application/pdf') navigate('/documents/pdf')
          else navigate('/documents/vimeo')
        }}
      >
        {optionsList.map((option) => {
          return (
            <Tabs.TabPane tab={option.name} key={option.key}>
              <DocumentsTable filters={{ mimeType: option.key }} />
            </Tabs.TabPane>
          )
        })}
      </Tabs>
    </List>
  )
}

type DocumentsTableProps = {
  filters: { mimeType: OptionKey }
}

function DocumentsTable(props: DocumentsTableProps) {
  const { filters } = props
  const { mimeType } = filters
  const { tableProps } = useTable<DocumentRead>({
    resource: ResourcePathEnum.documents,
    initialCurrent: 1,
    initialPageSize: 10,
    permanentSorter: [
      {
        field: 'id',
        order: 'desc',
      },
    ],
    initialFilter: [
      {
        field: 'mimeType',
        operator: 'eq',
        value: mimeType,
      },
    ],
  })

  return (
    <Table<DocumentRead> {...tableProps} rowKey={(user) => user.id}>
      <Table.Column dataIndex={'displayName'} title={'Titre du document'} />
      <Table.Column<DocumentRead>
        dataIndex={'languages'}
        title={'Langue'}
        render={(_, record) => {
          const { languages } = record
          const languagesList = languages.length
            ? languages.join(', ')
            : 'Multilingue'
          return <>{languagesList}</>
        }}
      />

      {mimeType === 'application/pdf' ? (
        <Table.Column<DocumentRead>
          dataIndex={'url'}
          title="Fichier PDF"
          render={(_, record) => {
            return (
              <Typography.Link href={record.url} target="_blank">
                {record.displayName}
              </Typography.Link>
            )
          }}
        />
      ) : (
        <Table.Column<DocumentRead>
          dataIndex={'url'}
          title="Lien Viméo"
          render={(_, record) => {
            return (
              <Typography.Link href={record.url} target="_blank">
                {record.displayName}
              </Typography.Link>
            )
          }}
        />
      )}

      <Table.Column<any>
        dataIndex={'updatedAt'}
        title="Dernière modification"
        render={(_, record) => {
          const { updatedAt, createdAt } = record
          const date = updatedAt ?? createdAt
          return (
            <Typography.Text>
              {date && new Date(date).toLocaleDateString('Fr')}
            </Typography.Text>
          )
        }}
      />

      <Table.Column<DocumentRead>
        dataIndex="actions"
        render={(_, record) => {
          const resource =
            record.mimeType === 'application/pdf'
              ? 'documents/pdf'
              : 'documents/vimeo'

          return (
            <Space style={{ justifyContent: 'flex-end', width: '100%' }}>
              <EditButton
                recordItemId={cleanHydraId(record.id)}
                resourceNameOrRouteName={resource}
              />
            </Space>
          )
        }}
      />
    </Table>
  )
}
